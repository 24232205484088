import { HttpError } from '@pankod/refine-core';
import { Edit, Box, TextField, Typography, Switch, FormLabel, Stack } from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';

import { IFoods } from 'interfaces';

export const FoodsEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<IFoods, HttpError, IFoods>({
    refineCoreProps: {
      metaData: {
        populate: '*',
      },
    },
  });

  return (
    <Edit
      title={<Typography variant="h6">Éditer ce produit</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: 'Enregistrer' }}
      canDelete
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        <FormLabel>Titre du produit</FormLabel>
        <TextField
          {...register('title', { required: 'Titre manquant' })}
          error={!!errors?.title}
          helperText={errors.title?.message}
          margin="normal"
          required
          multiline
          maxRows={3}
          fullWidth
          id="title"
          name="title"
        />
        <FormLabel>Description du produit</FormLabel>
        <TextField
          {...register('description')}
          margin="normal"
          required
          fullWidth
          id="description"
          multiline
          rows={3}
          name="description"
        />
        <FormLabel>Prix du produit</FormLabel>
        <TextField
          // register and validate only if it doesn't contain €
          {...register('price', {
            validate: (value) =>
              !value?.includes('€') ||
              'Le prix ne doit pas contenir €, il est automatiquement rajouté.',
          })}
          error={!!errors?.price}
          helperText={errors.price?.message}
          margin="normal"
          required
          fullWidth
          id="price"
          name="price"
        />
        <Stack marginTop={3}>
          <FormLabel>Végan ?</FormLabel>
          <Controller
            control={control}
            name="vegan"
            render={({ field }) => (
              <Switch
                checked={field.value ? true : false}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Edit>
  );
};
