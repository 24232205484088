// edit regions with select input

import { HttpError } from '@pankod/refine-core';
import { Edit, Box, TextField, Typography, FormLabel } from '@pankod/refine-mui';
import { useForm } from '@pankod/refine-react-hook-form';

import { IRegions } from 'interfaces';

export const RegionsEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm<IRegions, HttpError, IRegions>({
    refineCoreProps: {
      metaData: {
        populate: ['bottle_region', 'title'],
      },
    },
  });

  return (
    <Edit
      title={<Typography variant="h6">Éditer une région</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: 'Enregistrer' }}
      canDelete
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        <FormLabel>Titre de la région</FormLabel>
        <TextField
          {...register('title', { required: 'Titre manquant' })}
          error={!!errors?.title}
          helperText={errors.title?.message}
          margin="normal"
          required
          multiline
          maxRows={3}
          fullWidth
          id="title"
          name="title"
        />
      </Box>
    </Edit>
  );
};
