import { HttpError } from "@pankod/refine-core";
import {
  Create,
  Box,
  Typography,
  FormLabel,
  TextField,
} from "@pankod/refine-mui";
import { IHolidays } from "interfaces";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

export const HolidaysCreate: React.FC = () => {
  registerLocale("fr", fr);
  const {
    refineCore: { formLoading },
    saveButtonProps,
    control,
  } = useForm<IHolidays, HttpError, IHolidays>({
    refineCoreProps: {
      metaData: {
        populate: "*",
      },
    },
  });
  return (
    <Create
      title={
        <Typography variant="h6">Ajouter une période à bloquer</Typography>
      }
      isLoading={formLoading}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
      saveButtonProps={{ ...saveButtonProps, children: "Enregistrer" }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}
        autoComplete="off"
      >
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
            Date départ :
          </Typography>
        </FormLabel>
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <DatePicker
              placeholderText="Select date"
              onChange={(date) =>
                field.onChange(dayjs(date).format("YYYY-MM-DD"))
              }
              selected={
                typeof field.value === "string"
                  ? new Date(field.value)
                  : field.value
              }
              showMonthDropdown
              customInput={<TextField size="small" />}
              locale="fr"
              dateFormat={"dd/MM/yyyy"}
            />
          )}
        />
        <Box marginTop="2rem" marginBottom="2rem">
          <FormLabel component="legend">
            <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
              Date de fin :
            </Typography>
          </FormLabel>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                placeholderText="Select date"
                onChange={(date) =>
                  field.onChange(dayjs(date).format("YYYY-MM-DD"))
                }
                selected={
                  typeof field.value === "string"
                    ? new Date(field.value)
                    : field.value
                }
                customInput={<TextField size="small" />}
                showMonthDropdown
                locale="fr"
                dateFormat={"dd/MM/yyyy"}
              />
            )}
          />
        </Box>
      </Box>
    </Create>
  );
};
