import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
} from '@pankod/refine-mui';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ApiIcon from '@mui/icons-material/Api';
import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {useList} from '@pankod/refine-core';
import {IFeedback} from '../interfaces';

const FeedbackValue = () => {
    const {data, isLoading} = useList<IFeedback>({
        resource: 'satisfaction',
        config: {
            hasPagination: false,
        },
    });
    // @ts-ignore
    const record = data?.data?.feedbackValueAverageRound as number;

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <Fragment>
            <List
                breadcrumb={false}
                title={
                    <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Informations générales
                        <InfoIcon
                            sx={{
                                marginLeft: 1,
                            }}
                        />
                    </Typography>
                }
            >
                {record && <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <SentimentSatisfiedAltIcon/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Réservations"
                        secondary={
                            <Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Satisfaction globale des utilisateurs
                                </Typography>
                                {` — ${record}/5`}
                            </Fragment>
                        }
                    />
                </ListItem>}
                <Divider variant="middle"/>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <ApiIcon/>
                    </ListItemAvatar>
                    <Stack flexDirection={'column'}>
                        <ListItemText
                            primary="État de l'API"
                            secondary={
                                <Fragment>
                                    {
                                        <img
                                            src={
                                                'https://jitakude.ovh/api/badge/2/status?upLabel=En%20ligne&downLabel=Hors%20ligne'
                                            }
                                            loading={'lazy'}
                                            style={{
                                                marginRight: '.5rem',
                                            }}
                                            alt={"Impossible d'afficher l'état de l'API"}
                                        />
                                    }
                                    {
                                        <img
                                            src={
                                                'https://jitakude.ovh/api/badge/2/uptime/720?label=30&labelSuffix=%20jours'
                                            }
                                            loading={'lazy'}
                                            alt={"Impossible d'afficher l'état de l'API"}
                                        />
                                    }
                                </Fragment>
                            }
                        />
                    </Stack>
                </ListItem>
            </List>
        </Fragment>
    );
};

export const Dashboard = () => {
    const navigate = useNavigate();
    return (
        <Box marginTop={6}>
            <Typography variant="h4" marginBottom={3}>
                Tableau de bord
            </Typography>
            <List
                breadcrumb={false}
                title={
                    <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Opérations rapides{' '}
                        <ArrowDownwardIcon
                            sx={{
                                marginLeft: 1,
                            }}
                        />
                    </Typography>
                }
            >
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            onClick={() => {
                                navigate('/bookings');
                            }}
                        >
                            Voir les Réservations
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            onClick={() => {
                                navigate('/Dates/blockeds');
                            }}
                        >
                            Voir Jours bloqués
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            onClick={() => {
                                navigate('/Dates/holidays');
                            }}
                        >
                            Voir les périodes
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            onClick={() => {
                                navigate('/specials');
                            }}
                        >
                            Suggestions du jour
                        </Button>
                    </Grid>
                </Grid>
            </List>
            <Divider
                component={'hr'}
                sx={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    // stretch to fill the container
                    width: '100%',
                }}
            />
            <FeedbackValue/>
            <Divider
                component={'hr'}
                sx={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    // stretch to fill the container
                    width: '100%',
                }}
            />
            <List
                breadcrumb={false}
                title={
                    <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Liste des nouveautés (3.0.0){' '}
                        <WhatshotIcon
                            sx={{
                                marginLeft: 1,
                            }}
                        />
                    </Typography>
                }
            >
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <EngineeringIcon/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Refonte totale"
                        secondary={
                            <Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Fonctionnalités
                                </Typography>
                                {
                                    " — Refonte totale de l'application, du back et du front et ajout du QR Code"
                                }
                            </Fragment>
                        }
                    />
                </ListItem>
                {/*<Divider variant="middle" />*/}
                {/*<ListItem alignItems="flex-start">*/}
                {/*  <ListItemAvatar>*/}
                {/*    <EngineeringIcon />*/}
                {/*  </ListItemAvatar>*/}
                {/*  <Stack flexDirection={'column'}>*/}
                {/*    <ListItemText*/}
                {/*      primary="Réservations"*/}
                {/*      secondary={*/}
                {/*        <Fragment>*/}
                {/*          <Typography*/}
                {/*            sx={{ display: 'inline' }}*/}
                {/*            component="span"*/}
                {/*            variant="body2"*/}
                {/*            color="text.primary"*/}
                {/*          >*/}
                {/*            Amélioration*/}
                {/*          </Typography>*/}
                {/*          {*/}
                {/*            ' — Les réservations sont maintenant filtrées automatiquement par heure lors des recherches.'*/}
                {/*          }*/}
                {/*        </Fragment>*/}
                {/*      }*/}
                {/*    />*/}
                {/*    <ListItemText*/}
                {/*      secondary={*/}
                {/*        <Fragment>*/}
                {/*          <Typography*/}
                {/*            sx={{ display: 'inline' }}*/}
                {/*            component="span"*/}
                {/*            variant="body2"*/}
                {/*            color="text.primary"*/}
                {/*          >*/}
                {/*            Bug*/}
                {/*          </Typography>*/}
                {/*          {*/}
                {/*            " — Lors d'un retour en arrière une vue non prévue s'affichait contenant uniquement le nom, c'est maintenant corrigé."*/}
                {/*          }*/}
                {/*        </Fragment>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </Stack>*/}
                {/*</ListItem>*/}
            </List>
        </Box>
    );
};
