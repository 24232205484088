import React from 'react';
import { useDataGrid, DataGrid, GridColumns, List } from '@pankod/refine-mui';

import { IRegions } from 'interfaces';
import { useNavigate } from 'react-router-dom';

export const RegionsList: React.FC = () => {
  const navigate = useNavigate();
  // get base path from router
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IRegions>({
    initialSorter: [{ field: 'created_at', order: 'desc' }],
  });

  const columns = React.useMemo<GridColumns<IRegions>>(
    () => [
      {
        field: 'title',
        headerName: 'Titre',
        flex: 1,
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{ children: 'Ajouter' }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun résultat',
        }}
        density="compact"
      />
    </List>
  );
};
