import React, { useContext } from 'react';
import { useGetIdentity } from '@pankod/refine-core';
import { AppBar, IconButton, Avatar, Stack, Toolbar, Typography } from '@pankod/refine-mui';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import BugReportIcon from '@mui/icons-material/BugReport';
import { ColorModeContext } from 'contexts';

export const Header: React.FC = () => {
  const { mode, setMode } = useContext(ColorModeContext);

  const { data: user } = useGetIdentity();
  const showUserInfo = user && (user.name || user.avatar);

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => {
              window.open('https://wa.me/+33638300335', '_blank');
            }}
          >
            <BugReportIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setMode();
            }}
          >
            {mode === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
          </IconButton>
          {showUserInfo && (
            <Stack direction="row" gap="16px" alignItems="center">
              {user.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
              {user.name && <Typography variant="subtitle2">{user?.name}</Typography>}
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
