import { HttpError } from "@pankod/refine-core";
import {
  Edit,
  Box,
  Typography,
  Switch,
  FormLabel,
  TextField,
} from "@pankod/refine-mui";
import { IBlockeds } from "interfaces";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

export const BlockedsEdit: React.FC = () => {
  registerLocale("fr", fr);
  const {
    refineCore: { formLoading },
    saveButtonProps,
    control,
  } = useForm<IBlockeds, HttpError, IBlockeds>({
    refineCoreProps: {
      metaData: {
        populate: "*",
      },
    },
  });
  return (
    <Edit
      title={<Typography variant="h6">Modifier</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Enregistrer" }}
      canDelete
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
            Date à bloquer :
          </Typography>
        </FormLabel>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DatePicker
              placeholderText="Select date"
              onChange={(date) =>
                field.onChange(dayjs(date).format("YYYY-MM-DD"))
              }
              selected={
                typeof field.value === "string"
                  ? new Date(field.value)
                  : field.value
              }
              customInput={<TextField />}
              showMonthDropdown
              locale="fr"
              dateFormat={"dd/MM/yyyy"}
            />
          )}
        />
        <Box marginTop="2rem" marginBottom="2rem">
          <FormLabel component="legend">
            <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
              Bloquer le midi ?
            </Typography>
          </FormLabel>
          <Controller
            control={control}
            name="lunch"
            render={({ field }) => (
              <Switch
                checked={field.value ? true : false}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
              />
            )}
          />
          <FormLabel component="legend">
            <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
              Bloquer le soir ?
            </Typography>
          </FormLabel>
          <Controller
            control={control}
            name="evening"
            render={({ field }) => (
              <Switch
                checked={field.value ? true : false}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Edit>
  );
};
