import { HttpError } from '@pankod/refine-core';
import {
  Create,
  Box,
  TextField,
  Typography,
  Switch,
  FormLabel,
  Stack,
  useAutocomplete,
  Autocomplete,
} from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';

import { IRegions, IWines } from 'interfaces';

export const WinesCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<IWines, HttpError, IWines>({
    refineCoreProps: {
      metaData: {
        populate: '*',
      },
    },
  });

  const { autocompleteProps } = useAutocomplete<IRegions>({
    resource: 'regions',
  });

  return (
    <Create
      title={<Typography variant="h6">Ajouter ce vin</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: 'Enregistrer' }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        <FormLabel>Titre du vin*</FormLabel>
        <TextField
          {...register('title', { required: 'Titre manquant' })}
          error={!!errors?.title}
          helperText={errors.title?.message}
          margin="normal"
          required
          multiline
          maxRows={3}
          fullWidth
          id="title"
          name="title"
        />
        <FormLabel>Millésime *</FormLabel>
        <TextField
          // register and validate only if it doesn't contain €
          {...register('vintage', { required: 'Millésime manquant' })}
          error={!!errors?.vintage}
          helperText={errors.vintage?.message}
          margin="normal"
          required
          fullWidth
          id="vintage"
          name="vintage"
        />
        <FormLabel>Prix du vin*</FormLabel>
        <TextField
          // register and validate only if it doesn't contain €
          {...register('price', {
            validate: (value) =>
              !value?.includes('€') ||
              'Le prix ne doit pas contenir €, il est automatiquement rajouté.',
          })}
          error={!!errors?.price}
          helperText={errors.price?.message}
          margin="normal"
          required
          fullWidth
          id="price"
          name="price"
        />
        <FormLabel>Description du vin</FormLabel>
        <TextField
          {...register('description')}
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          multiline
          rows={3}
          name="description"
        />
        <Stack marginTop={3}>
          <FormLabel>Vin bio ?</FormLabel>
          <Controller
            control={control}
            name="bio"
            render={({ field }) => (
              <Switch
                checked={field.value ? true : false}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
              />
            )}
          />
        </Stack>
        <FormLabel>Région</FormLabel>
        <Controller
          control={control}
          name="bottle_region"
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={field.value}
              getOptionLabel={(item) => {
                return item.title
                  ? item.title
                  : autocompleteProps?.options?.find((p) => p.id.toString() === item.toString())
                      ?.title ?? '';
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id.toString() === value.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Région"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.bottle_region}
                  helperText={errors.bottle_region?.message}
                  required
                />
              )}
            />
          )}
        />
      </Box>
    </Create>
  );
};
