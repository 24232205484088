function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="45" fill="none">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M0 595.276h841.89V0H0z"></path>
        </clipPath>
      </defs>
      <g fill="#fff" fillOpacity="0.941">
        <path d="M14.376 5.707l-1.234-1.253-1.234 1.253 1.234 1.253zM23.38 5.707l-1.234-1.253-1.235 1.253 1.235 1.253z"></path>
        <g
          clipPath="url(#a)"
          transform="matrix(.20919 0 0 -.21237 -70.31 95.138)"
        >
          <path d="M449.786 255.961v38.396a15.134 15.134 0 018.313 5.57v-36.182a50.097 50.097 0 00-8.313-7.784m-57.253-1.437v39.36h4.639c6.972 0 12.644 5.672 12.644 12.643v17.289h7.345a1.667 1.667 0 011.565 2.24l-8.91 19.608v25.91h40.52c-11.762-5.998-19.615-19.922-19.615-32.706v-10.194h1.515a4.402 4.402 0 004.398-4.397 4.403 4.403 0 00-4.398-4.397h-1.515v-25.996h15.311c.242 0 .483.007.722.018v-40.021a49.34 49.34 0 00-26.486-7.676c-10.046 0-19.621 2.909-27.735 8.319m-11.33 10.417v24.076a4.874 4.874 0 004.868 4.867h3.43v-37.168a49.535 49.535 0 00-8.298 8.225m-10.57 83.066c12.655.756 22.815 10.913 23.577 23.567h12.574v-26.567l8.258-18.16h-8.258v-20.32c0-5.3-4.311-9.612-9.612-9.612h-11.101c-4.356 0-7.899-3.543-7.899-7.898v-19.814c-4.91 7.751-7.539 16.759-7.539 26.196zm8.511 46.373l-8.511-7.921v7.921zm90.759-8.323h-60.076v8.323h60.076zm-20.117-79.034c2.807.016 5.614.03 8.146.041-.715-4.49-3.917-8.158-8.146-9.547zm-7.814-.048l4.782.03v-10.066a11.504 11.504 0 00-.722-.024h-4.06zm-8.219-.055l5.187.036v-10.041h-5.187zm-23.925 76.106h60.075v-8.421h-60.075zm-26.781 11.354h23.014l-13.086-9.438zm-12.414-35.446c6.644.706 11.939 5.997 12.649 12.64h7.888c-.753-10.981-9.554-19.78-20.537-20.528zm0 12.64h9.594c-.679-4.969-4.624-8.91-9.594-9.585zm36.151 3.031h-36.151v7.712l10.461 9.737 11.615-11.042 14.087 10.161v-16.568zm26.969-43.055v7.318c0 7.887 3.117 16.064 8.553 22.432 5.656 6.625 12.891 10.274 20.374 10.274h7.223v-55.09h-4.267a7.366 7.366 0 01-5.295-2.141 7.492 7.492 0 01-2.164-4.247c-8.493-.034-20.273-.115-24.424-.145v7.053c3.371.702 5.912 3.696 5.912 7.273 0 3.577-2.541 6.571-5.912 7.273m27.377-63.854v40.89h-.024c.003.127.01.253.01.381 0 1.215.48 2.358 1.352 3.217a4.333 4.333 0 003.145 1.269h4.29V295.84a49.36 49.36 0 00-8.773-28.144m11.805 28.144v101.571H367.601V295.399c0-14.043 5.481-27.193 15.433-37.026 9.919-9.802 23.143-15.199 37.234-15.199 29.04 0 52.667 23.626 52.667 52.666"></path>
          <path d="M450.317 342.076a4.402 4.402 0 004.396-4.397 4.402 4.402 0 00-4.396-4.397 4.401 4.401 0 00-4.396 4.397 4.401 4.401 0 004.396 4.397m0-11.825c4.096 0 7.429 3.332 7.429 7.428s-3.333 7.428-7.429 7.428-7.429-3.332-7.429-7.428 3.333-7.428 7.429-7.428M432.353 287.05l-12.085-12.086-12.085 12.086-3.719-3.72 12.085-12.085-12.085-12.084 3.719-3.719 12.085 12.085 12.085-12.085 3.719 3.719-12.085 12.084 12.085 12.085zM379.817 407.165h80.844l12.332 15.96-3.525 2.71-11.924-15.639h-34.647v30.227h-5.259v-30.227h-34.822l-10.985 16.221-3.494-2.371z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;