import React from "react";
import { useDataGrid, DataGrid, GridColumns, List } from "@pankod/refine-mui";

import { IWines } from "interfaces";
import { useNavigate } from "react-router-dom";

export const WinesList: React.FC = () => {
  const navigate = useNavigate();
  // get base path from router
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IWines>({
    initialSorter: [{ field: "created_at", order: "desc" }],
  });

  const columns = React.useMemo<GridColumns<IWines>>(
    () => [
      {
        field: "title",
        headerName: "Titre",
        flex: 1,
      },
      {
        field: "price",
        headerName: "Prix",
      },
      {
        field: "vintage",
        headerName: "A",
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{ children: "Ajouter" }}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: "Aucun résultat",
        }}
        density="compact"
      />
    </List>
  );
};
