// disable typescript eslint checks for this file
import { QrScanner } from "@yudiel/react-qr-scanner";

import { useList, useUpdate } from "@pankod/refine-core";
import {
  Box,
  Button,
  FormLabel,
  Show,
  Stack,
  Switch,
  Typography,
} from "@pankod/refine-mui";
import { useState } from "react";

export const GiftCardsReader = () => {
  const [result, setResult] = useState<string>("");
  const [matchingId, setMatchingId] = useState<string>("");
  const [display, setDisplay] = useState<boolean>(false);
  const [startScanning, setStartScanning] = useState<boolean>(true);

  const { data, isLoading } = useList<any>({
    resource: "giftcards",
    config: {
      hasPagination: false,
    },
  });

  const record = data?.data;

  const mutationResult = useUpdate<any>();

  const { mutate, isLoading: mutateIsLoading } = mutationResult;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleScan = (result: any) => {
    setResult(result);
    setDisplay(true);
    setStartScanning(false);

    // find id for which giftCardId is equal to result
    setMatchingId(
      (record?.find((item: any) => item.giftCardId === result) || {}).id
    );
  };

  const handleDisplay = (item: any, used: any) => {
    mutate({
      resource: "giftcards",
      // find id with giftCardId
      id: matchingId,
      values: { ...item, used },
    });
  };

  const handleError = (error: any) => {
    console.log({ error });
  };

  return (
    <Show
      title={<Typography variant="h6">Scanner une carte cadeau</Typography>}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
      resource="giftcards"
      isLoading={isLoading}
      goBack={false}
      canEdit={false}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {startScanning ? (
          <QrScanner
            videoId="scanner"
            constraints={{
              width: 640,
              height: 480,
              facingMode: "environment",
            }}
            onDecode={handleScan}
            onError={(error) => console.log(error?.message)}
          />
        ) : (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Scanner une autre carte cadeau
            </Button>
          </Box>
        )}
      </Box>
      {display && (
        <Box>
          {record?.find((item: any) => item.giftCardId === result)?.used ? (
            <Stack>
              <Typography
                fontSize="1.5rem"
                fontWeight="600"
                marginTop="1rem"
                color="red"
              >
                ATTENTION : cette carte cadeau a été marquée comme utilisée !
              </Typography>
              <Typography>
                Veuillez ignorer ce message si vous venez de marquer la carte
                cadeau comme utilisée.
              </Typography>
            </Stack>
          ) : (
            <Stack>
              <Typography
                fontSize="1.5rem"
                fontWeight="600"
                marginTop="1rem"
                color="green"
              >
                Cette carte cadeau n'a pas encore été marquée comme utilisée.
              </Typography>
            </Stack>
          )}
          <FormLabel component="legend">
            <Typography fontSize="1rem" fontWeight="600" marginTop="1rem">
              Marquer la carte cadeau comme utilisée :
            </Typography>
          </FormLabel>
          <Switch
            name="used"
            checked={
              record?.find((item: any) => item.giftCardId === result)?.used
            }
            // update corresponding record
            onChange={(e) =>
              handleDisplay(
                record?.find((item: any) => item.id === result),
                e.target.checked
              )
            }
          />

          <Stack marginTop="1rem" marginBottom="1rem">
            <Typography fontSize={"1.25rem"}>
              Infos liées à cette carte cadeau :
            </Typography>
            {record?.find((item: any) => item.giftCardId === result) && (
              <Typography fontWeight="600">
                Date d'achat :{" "}
                {new Date(
                  record?.find(
                    (item: any) => item.giftCardId === result
                  )?.createdAt
                ).toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
              </Typography>
            )}
            {record?.find((item: any) => item.giftCardId === result) && (
              <Typography fontWeight="600">
                Nom :{" "}
                {record?.find((item: any) => item.giftCardId === result)?.name}
              </Typography>
            )}
            {record?.find((item: any) => item.giftCardId === result) && (
              <Typography fontWeight="600">
                Montant réglé en ligne :{" "}
                {
                  record?.find((item: any) => item.giftCardId === result)
                    ?.amount
                }{" "}
                €
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </Show>
  );
};
