import { HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Typography,
  Create,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IBookings } from "interfaces";
import ReactDatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

export const BookingsCreate: React.FC = () => {
  registerLocale("fr", fr);
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<IBookings, HttpError, IBookings>({
    refineCoreProps: {
      metaData: {
        populate: "*",
      },
    },
  });

  return (
    <Create
      title={<Typography variant="h6">Ajouter une réservation</Typography>}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Enregistrer" }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormLabel>Filtrer par date</FormLabel>
        <RadioGroup
          row
          {...register("gender")}
          sx={{
            marginTop: "1rem",
          }}
        >
          <FormControlLabel value="M." control={<Radio />} label="M." />
          <FormControlLabel value="Mme" control={<Radio />} label="Mme" />
        </RadioGroup>

        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Nom du client :
          </Typography>
        </FormLabel>
        <TextField
          {...register("name")}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          variant="outlined"
          fullWidth
          id="name"
          name="name"
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Téléphone :
          </Typography>
        </FormLabel>
        <TextField
          {...register("phone")}
          error={!!errors?.phone}
          helperText={errors.phone?.message}
          margin="normal"
          variant="outlined"
          fullWidth
          id="phone"
          name="phone"
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Email :
          </Typography>
        </FormLabel>
        <TextField
          {...register("email")}
          error={!!errors?.email}
          helperText={errors.email?.message}
          margin="normal"
          variant="outlined"
          fullWidth
          id="email"
          name="email"
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Nombre de couverts
          </Typography>
        </FormLabel>
        <TextField
          {...register("partySize")}
          error={!!errors?.partySize}
          helperText={errors.partySize?.message}
          margin="normal"
          variant="outlined"
          id="partySize"
          name="partySize"
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Date souhaitée :
          </Typography>
        </FormLabel>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <ReactDatePicker
              onChange={(date) =>
                field.onChange(dayjs(date).format("YYYY-MM-DD"))
              }
              selected={
                typeof field.value === "string"
                  ? new Date(field.value)
                  : field.value
              }
              showMonthDropdown
              customInput={<TextField fullWidth />}
              locale="fr"
              dateFormat={"dd/MM/yyyy"}
            />
          )}
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Heure souhaitée :
          </Typography>
        </FormLabel>
        <TextField
          {...register("time")}
          error={!!errors?.time}
          helperText={errors.time?.message}
          margin="normal"
          variant="outlined"
          id="time"
          name="time"
        />
        <FormLabel component="legend">
          <Typography
            fontSize="1rem"
            fontWeight="600"
            marginBottom="0.5rem"
            marginTop="0.8rem"
          >
            Demandes spéciales :
          </Typography>
        </FormLabel>
        <TextField
          {...register("requests")}
          error={!!errors?.requests}
          helperText={errors.requests?.message}
          margin="normal"
          variant="outlined"
          id="request"
          name="request"
          multiline
          rows={4}
        />
      </Box>
    </Create>
  );
};
