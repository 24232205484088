import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Typography,
} from "@pankod/refine-mui";

import { IHolidays } from "interfaces";
import { useNavigate } from "react-router-dom";

export const HolidaysList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IHolidays>({
    initialSorter: [{ field: "date", order: "desc" }],
  });

  const navigate = useNavigate();
  const basePath = window.location.pathname;
  const columns = React.useMemo<GridColumns<IHolidays>>(
    () => [
      {
        field: "startDate",
        headerName: "Date",
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography
            fontSize={14}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/blockeds/edit/${params.row.id}`)}
          >
            {params.row.startDate &&
              new Date(params.row.startDate).toLocaleDateString("fr-FR", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: "endDate",
        headerName: "Date de fin",
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography
            fontSize={14}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/blockeds/edit/${params.row.id}`)}
          >
            {params.row.endDate &&
              new Date(params.row.endDate).toLocaleDateString("fr-FR", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })}
          </Typography>
        ),
        flex: 1,
      },
    ],
    [navigate]
  );

  return (
    <List
      createButtonProps={{
        children: "Ajouter une période",
        sx: { marginTop: "1rem" },
      }}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        // go to edit view onClick
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: "Aucune période bloquée pour le moment",
        }}
        autoHeight
        density="compact"
      />
    </List>
  );
};
